<template>
  <div>
    <div id="main">
      <div class="bg-other user-head-info">
        <div class="user-info clearfix">
          <div class="user-pic" data-is-fans="0" data-is-follows="">
            <div class="user-pic-bg">
              <img
                class="img"
                src="//img3.sycdn.imooc.com/576f872a00011f2d01000100-140-140.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="user-info-right">
            <h3 class="user-name clearfix">
              <span>{{ user.userName }}</span>
            </h3>
            <p class="about-info">
              <span>{{ user.sex }}</span>
              <a class="more-user-info"
                ><i class="imv2-arrow2_d"></i>更多信息</a
              >
            </p>
          </div>
          <div class="user-sign hide">
            <p class="user-desc">这位同学很懒，木有签名的说～</p>
          </div>
          <div class="study-info clearfix">
            <div class="item follows">
              <div
                class="u-info-learn"
                title="学习时长2小时5分"
                style="cursor: pointer"
              >
                <em>{{ user.sc }}h</em>
                <span>学习时长 </span>
              </div>
            </div>
            <div class="item follows">
              <a href="#/grxx" class="set-btn"
                ><i class="icon-set"></i>个人设置</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="slider" style="position: absolute; left: 24px; top: 0px">
          <ul>
            <li>
              <a
                @click="act = '我的课程'"
                href="#"
                :class="{ active: act == '我的课程' }"
              >
                <i class="imv2-play_circle"></i><span>我的课程</span
                ><b class="icon-drop_right"></b>
              </a>
            </li>
            <li>
              <a
                @click="act = '我的课表'"
                href="#"
                :class="{ active: act == '我的课表' }"
              >
                <i class="new-icn"></i> <i class="imv2-list_bulleted"></i
                ><span>我的课表</span><b class="icon-drop_right"></b>
              </a>
            </li>
            <li>
              <a
                @click="act = '收藏'"
                href="#"
                :class="{ active: act == '收藏' }"
              >
                <i class="imv2-star-revert"></i><span>收藏</span
                ><b class="icon-drop_right"></b>
              </a>
            </li>
          </ul>
          <div id="personalLeft"></div>
        </div>
        <div class="u-container">
          <div class="c-tab clearfix">
            <div class="tool-left l">
              <a
                v-for="(v, i) in ['全部', '免费课', '实战课', '体系课', '微课']"
                href="#"
                class="sort-item"
                @click="bct = v"
                :class="{ active: bct == v }"
                :key="i"
                >{{ v }}</a
              >
            </div>
          </div>
          <div class="all-course-main">
            <div class="allcourse-content js-course-list">
              <div v-for="(v, i) in data" :key="i" class="courseitem tl-item">
                <div class="img-box">
                  <a :href="`#/video?courseid=${v.courseid}`" target="_blank">
                    <img
                      width="200"
                      height="116"
                      alt="如何从0到1，写出一份完美的PRD文档"
                      :src="v.image"
                    />
                  </a>
                </div>
                <div class="info-box course-list">
                  <div class="title">
                    <span>{{ v.type }}</span>
                    <a
                      :href="`#/video?courseid=${v.courseid}`"
                      class="hd"
                      target="_blank"
                      >{{ v.title }}</a
                    >
                  </div>
                  <div class="study-info">
                    <span class="i-left span-common">已学{{ v.yx }}%</span>
                    <span class="i-mid span-common">用时 {{ v.ys }}分钟</span>
                    <span class="i-right span-common">学习至{{ v.xxz }}</span>
                  </div>
                  <div class="catog-points">
                    <span class="i-right span-common">
                      <a :href="`#/video?courseid=${v.courseid}`" target="_blank"
                        >问答 <i>{{ v.wd }}</i></a
                      >
                    </span>
                    <a
                      class="btn-red continute-btn"
                      v-show="act == '收藏'"
                      href="#"
                      @click="cancel(v.courseid)"
                      >取消收藏</a
                    >
                    <a
                      v-show="act !== '收藏'"
                      href="#/video?id=131"
                      target="_blank"
                      class="btn-red continute-btn"
                      >继续学习</a
                    >
                  </div>
                  <div class="share-box clearfix course-one">
                    <div class="show-btn"><i class="icon-down2"></i></div>
                    <div class="share-box-con">
                      <div class="bdsharebuttonbox bdshare-button-style0-16">
                        <a href="javascript:;" title="删除" class="del"
                          ><i class="icon icon-notdisplay"></i
                        ></a>
                        <a
                          class="bds_addFirst js-go-top js-placedTop-zhuge"
                          data-cmd=""
                          href="#"
                          title="置顶课程"
                          data-top="1"
                          ><i class="imv2-tuwen1"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../ulti/ajax";
export default {
  data() {
    return {
      // 课程列表
      data: [],
      act: "我的课程",
      bct: "全部",
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    act:(newVal, oldVal)=>{
      this.renderData()
    },
    bct:()=>{
      this.renderData()
    }
  },
  methods: {
    // 取消收藏课程
  async  cancel(courseid) {
    let data = (await api.cancelCourseid(courseid)).data;
      this.renderData();
    },
    // 请求数据
  async  renderData(){
      // 请求数据重新渲染
     this.data = (await api.getgrzxData(this.act,this.btc)).data;
    }
  },
  created(){
    this.renderData()
  }
};
</script>

<style>
@import url('../../assets/css/grxx.css');
</style>
